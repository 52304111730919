import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { HiBellAlert } from "react-icons/hi2";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  FaDotCircle,
  FaHome,
  FaMagnet,
  FaUser,
  FaUsers,
  FaStore,
} from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa6";
import PhonePausedRoundedIcon from "@mui/icons-material/PhonePausedRounded";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import LeadManagementTable from "../LeadManagement/LeadManagementTable";
import NotesRoundedIcon from "@mui/icons-material/NotesRounded";
import ProjectStatustAndGraf from "../DashboardHomePage/ProjectStatusAndGraf";
import { Link, Route, Routes } from "react-router-dom";
// import { Redirect } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Basic from "../ProjectManegement/Basic";
import Design from "../ProjectManegement/Design";
import Common from "../ProjectManegement/Common";
import Other from "../ProjectManegement/Other";
import Pricing from "../ProjectManegement/Pricing";
import Payment from "../ProjectManegement/Payment";
import Admin from "../UserAccountSetting/UserAccountSetting";
import Customer from "../CstmrManagement/Customers";
import Blocks from "../ProjectManegement/Blocks";
import Floors_Or_Unit from "../ProjectManegement/Floors_Or_Unit";
import CreateBilling from "../CstmrManagement/Billing";
import AddUnits from "../ProjectManegement/AddUnits";
import PurchaseEntry from "../StoreManagement/PurchaseEntry";
import StoreEntry from "../StoreManagement/StoreEntry";
import PurchaseMaster from "../StoreManagement/PurchaseMaster";
import { Dashboard } from "@material-ui/icons";
import DashBoardHomePage from "../DashboardHomePage/DashBoardHomePage";
import Towers from "../ProjectManegement/Towers";
import Primary from "../LeadManagement/Primary";
import Allocation from "../LeadManagement/Allocation";
import PaymentStatus from "../CstmrManagement/PaymentStatus";
import SiteVisit from "../CstmrManagement/SiteVisit";
import BlockForm from "../CstmrManagement/BlockForm";
import CustomerBooking from "../CstmrManagement/CustomerBooking";
import Sales from "../Sales-Dashboard/Sales";
import Wellcome from "../DashboardHomePage/Wellcome";
import LeadTransction from "../LeadManagement/LeadTransction";
import CustomerSales from "../CstmrManagement/CustomerSales";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Typicalunits from "../ProjectManegement/Typicalunits";
import Costadd from "../ProjectManegement/Costadd";
import Copybasic from "../ProjectManegement/Copybasic";
import Salescopy from "../Sales-Dashboard/Salescopy";
import Manger from "../ManagerDashboard/Manger";
import CrmHead from "../CrmDashboard/CrmHead";
import AccountantDashboard from "../AccountantDashboard/AccountantDashboard";
import { HiMiniBellAlert } from "react-icons/hi2";

import { useNavigate } from "react-router-dom";
import PreSales from "../PreSales/PreSales";
import CancelCustomers from "./CancelCustomers";
import { IoChatbubbleEllipses } from "react-icons/io5";
import AdminCommunication from "../AdminCommunication/AdminCommunication";
import PaymentApprove from "../AccountantDashboard/PaymentApprove";
import ProjectReport from "../MisReports/ProjectReport";
import LeadReport from "../MisReports/LeadReports";
import SalesReport from "../MisReports/SalesReports";
import Alerts from "../ManagerDashboard/Alerts";
import DocumentTable from "./DocumentTable";
import AgentReport from "../MisReports/AgentReport";
import AdminDashboard from "../AdminDashboard/AdminDashboard";

const drawerWidth = 257;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function MainDashboard() {
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open5 = Boolean(anchorEl1);
  const handleClick5 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl1(null);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [open1, setOpen1] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(!open1);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const [open2sm, setOpen2sm] = React.useState(false);

  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen1(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick2sm = () => {
    setOpen2(false);
    setOpen2sm(!open2sm);
    setOpen1(false);
    setOpen3(false);
    setOpen4(false);
  };

  const [open3, setOpen3] = React.useState(false);

  const handleClick3 = () => {
    setOpen3(!open3);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };
  const [open6, setOpen6] = React.useState(false);

  const handleClick6 = () => {
    setOpen6(!open6);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };

  const [open7, setOpen7] = React.useState(false);

  const handleClick7 = () => {
    setOpen7(!open7);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };

 const ProjectName=sessionStorage.getItem('ProjectName')

  const [open4, setOpen4] = React.useState(false);

  const [opens, setOpens] = React.useState(false);

  const handleClicks = () => {
    setOpens(!opens);
  };

  const [openss, setOpenss] = React.useState(false);

  const handleClickss = () => {
    setOpenss(!openss);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    const userType = sessionStorage.getItem("userType");
    const admin = sessionStorage.getItem("superAdmin");
    if (userType === "Sales Executive") {
      navigate("/sales");
    } else if (userType === "Manager") {
      navigate("/managerdashboard");
    } else if (admin === "superLogin") {
      navigate("/admin");
    } else if (userType === "CRM Head") {
      navigate("/crmdashboard");
    }  else if (userType === "CRM Executive") {
      navigate("/crmdashboard");
    }else if (userType === "Admin") {
      navigate("/admin-dashboard");
    } 
    
    else if (userType === "Accountant") {
      navigate("/account-dashboard");
    }
  }, []);


  const userTypes=sessionStorage.getItem('userType')

  return (
    <Box className="main-container" sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        style={{ padding: 2 }}
        className="sidebar-container scroll"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Divider />

        <div
          style={{
            marginBottom: 20,
            marginLeft: 15,
            marginRight: 10,
            marginTop: 24,
            backgroundColor: "rgb(126, 110, 228)",
            borderRadius: "0.2rem",
            padding: "disabled",
          }}
        >
          <img
            src={require("../../../assets/images/buildfast-logo.png")}
            width="200px"
            height="50px"
            style={{ marginLeft: 17 }}
          />
        </div>

        <div>
          {sessionStorage.getItem("superAdmin") && (
            <List>
              <Link to="/admin" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          )}

          {sessionStorage.getItem("salesBoard") === "display" && (
            <List>
              <Link to="/sales" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          )}
          {sessionStorage.getItem("mangerdash") === "1" && (
            <>
            <List>
              <Link to="/managerdashboard" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to="/alerts" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                      marginTop:16
                    }}
                  >
                    <ListItemIcon>
                      <HiBellAlert></HiBellAlert>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Alerts</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
             
           </>
          )}

{sessionStorage.getItem("admindashboard") === "1" && (
            <>
            <List>
              <Link to="/admin-dashboard" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
             
            </List>
             
           </>
          )}
          {sessionStorage.getItem("crmdash") === "1" && (
            <List>
              <Link to="/crmdashboard" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          )}




          {sessionStorage.getItem("accountdash") === "1" && (
            <List>
              <Link to="/account-dashboard" style={{ textDecoration: "none" }}>
                <ListItem disablePadding>
                  <ListItemButton
                    style={{
                      borderRadius: "0.2rem",
                      marginRight: 11,
                      marginLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <FaHome></FaHome>
                    </ListItemIcon>
                    <Typography
                      style={{ color: "rgb(126, 110, 228)", fontSize: 13.4 }}
                    >
                      <strong>Dashboard</strong>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Link>
              <ListItemButton onClick={handleClickss}>
                <ListItemIcon>
                  <FaFileInvoice></FaFileInvoice>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Customers Billing"
                />
                {openss ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openss} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <Link
                    to="/Pre-sales"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="PreSales"
                        />
                      </ListItemButton>
                    
                    </List>
                  </Link>
                  <Link
                    to="/payment-approve"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Sales"
                        />
                      </ListItemButton>
                    
                    </List>
                  </Link>
                </List>

             
              </Collapse>
            </List>
          )}
          {(sessionStorage.getItem("UsersAccountSetting") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton
                onClick={() => {
                  setOpen4(!open4);
                  setOpen2(false);
                  setOpen3(false);
                  setOpen1(false);
                }}
              >
                <ListItemIcon>
                  <FaUsers></FaUsers>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="User Account Setting"
                />
                {open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link
                    to="/project-admin-account"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Account Setting"
                        />
                      </ListItemButton>
                    </List>
                  </Link>
                </List>
              </Collapse>
            </List>
          )}

          {/* {
                        (sessionStorage.getItem('LeadManagement') === '1' || sessionStorage.getItem('superAdmin')) && (
                            <Link to='/leadmanagement' style={{ textDecoration: 'none' }}>
                            <List>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PhonePausedRoundedIcon ></PhonePausedRoundedIcon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color:'rgb(126, 110, 228)' }} primary='Lead Management' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            </Link>
                        )
                    } */}

          {(sessionStorage.getItem("LeadManagement") === "1" || 
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClickss}>
                <ListItemIcon>
                  <PhonePausedRoundedIcon></PhonePausedRoundedIcon>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Lead Management"
                />
                {openss ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openss} timeout="auto" unmountOnExit>
                {(sessionStorage.getItem("Primary") === "1" ||
                  sessionStorage.getItem("superAdmin")) && (
                  <Link
                    to="/primary"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Primary"
                        />
                      </ListItemButton>
                    </List>
                  </Link>
                )}
                {/* {
                                        (sessionStorage.getItem('Secondary') === '1' || sessionStorage.getItem('superAdmin')) && (
                                            <Link to='/allocation' style={{ color: 'black', textDecoration: 'none' }} >
                                                <List component="div" disablePadding>
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Allocation" />
                                                    </ListItemButton>
                                                </List>
                                            </Link>

                                        )
                                    } */}
                {/* {
                                        (sessionStorage.getItem('Lead Transction') === '1' || sessionStorage.getItem('superAdmin')) && (
                                            <Link to='/lead-transction' style={{ color: 'black', textDecoration: 'none' }} >
                                                <List component="div" disablePadding>
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Lead Transction" />
                                                    </ListItemButton>
                                                </List>
                                            </Link>

                                        )
                                    } */}
                {/* {
                                        (sessionStorage.getItem('Allocation') === '1' || sessionStorage.getItem('superAdmin')) && (
                                            <Link to='/leadmanagement' style={{ color: 'black', textDecoration: 'none' }} >
                                                <List component="div" disablePadding>
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Secondary" />
                                                    </ListItemButton>
                                                </List>
                                            </Link>

                                        )
                                    } */}
              </Collapse>
            </List>
          )}

          {/* {
                        (sessionStorage.getItem('ContactManagement') === '1' || sessionStorage.getItem('superAdmin')) && (
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PhonePausedRoundedIcon></PhonePausedRoundedIcon>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color:'rgb(126, 110, 228)' }} primary='Contact Management' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        )
                    } */}

          {(sessionStorage.getItem("ProjectManagement") === "1" || (sessionStorage.getItem("userType")==="Admin") ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick1}>
                <ListItemIcon>
                  <AppsRoundedIcon></AppsRoundedIcon>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Project Management"
                />
                {open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open1} timeout="auto" unmountOnExit>
                <Link
                  to="/project-basic"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <FaDotCircle style={{ fontSize: 11 }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "13.6px",
                          color: "rgb(126, 110, 228)",
                        }}
                        primary="Projects"
                      />
                    </ListItemButton>
                  </List>
                </Link>
                {/* <Link to='/project-cost' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Pricing" />
                                            </ListItemButton>
                                        </List>
                                    </Link> */}
                {/* <Link to='/project-common' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Common Amenities" />
                                            </ListItemButton>
                                        </List>
                                    </Link> */}
                {/* <Link to='/project-blocks' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Project Blocks" />
                                            </ListItemButton>
                                        </List>
                                    </Link>

                                    <Link to='/project-towers' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Towers" />
                                            </ListItemButton>
                                        </List>
                                    </Link>

                                    <Link to='/project-floor' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Block Floors" />
                                            </ListItemButton>
                                        </List>
                                    </Link>
                                    <Link to='/project-unit' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Add unit" />
                                            </ListItemButton>
                                        </List>
                                    </Link>

                                    <Link to='/project-design' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Design" />
                                            </ListItemButton>
                                        </List>
                                    </Link>



                                    <Link to='/project-common' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Common Amenities" />
                                            </ListItemButton>
                                        </List>
                                    </Link> */}

                {/* <Link to='/project-other' style={{ color: 'black', textDecoration: 'none' }}>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Other" />
                                            </ListItemButton>
                                        </List>
                                    </Link> */}

                {/* <Link to='/project-pricing' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Pricing" />
                                            </ListItemButton>
                                        </List>
                                    </Link>
                                    <Link to='/project-instalments' style={{ color: 'black', textDecoration: 'none' }} >
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <FaDotCircle style={{ fontSize: 11 }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Payments" />
                                            </ListItemButton>
                                        </List>
                                    </Link> */}
              </Collapse>
            </List>
          )}

          {/* {
                        (sessionStorage.getItem('CstmrManagement') === '1' || sessionStorage.getItem('superAdmin')) && (
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton onClick={handleClick2sm}>
                                    <ListItemIcon>
                                        <AttachMoneyIcon/>
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Sales Management" />
                                    {open2sm ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open2sm} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {
                                            (sessionStorage.getItem('Site Visit Form') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/site-visit' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Site Visit" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        }
                                        {
                                            (sessionStorage.getItem('Block-Form') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/block-form' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Block Form" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        }
                                        {
                                            (sessionStorage.getItem('Book Customer') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/book-customer' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Book Customer" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        }
  
                                    </List>
                                </Collapse>
                            </List>
                        )
                    } */}

          {(sessionStorage.getItem("CstmrManagement") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick2}>
                <ListItemIcon>
                  <FaUsers></FaUsers>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Customer Management"
                />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* {
                                            (sessionStorage.getItem('Site Visit Form') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/site-visit' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Site Visit" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        } */}
                  {/* {
                                            (sessionStorage.getItem('Block-Form') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/block-form' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Block Form" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        } */}
                  {/* {
                                            (sessionStorage.getItem('Book Customer') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/book-customer' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Book Customer" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        } */}
                  {(sessionStorage.getItem("Customers") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/customer-sales"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Sales"
                        />
                      </ListItemButton>
                    </Link>
                  )}
                  {/* {
                                            (sessionStorage.getItem('Customers') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                <Link to='/create-customer' style={{ color: 'black', textDecoration: 'none' }} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FaDotCircle style={{ fontSize: 11 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color: 'rgb(126, 110, 228)' }} primary="Customers" />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        } */}

                  {(sessionStorage.getItem("Billing") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/customer-billing"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Billing"
                        />
                      </ListItemButton>
                    </Link>
                  )}

                  {(sessionStorage.getItem("Payment") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/payment"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Payment"
                        />
                      </ListItemButton>
                    </Link>
                  )}

                  {/* <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <FaDotCircle style={{ fontSize: 11 }} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ fontSize: '13.6px', color:'rgb(126, 110, 228)' }} primary="Payment" />
                                        </ListItemButton> */}
                </List>
              </Collapse>
            </List>
          )}

          {(sessionStorage.getItem("Storemanagment") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClicks}>
                <ListItemIcon>
                  <FaStore />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Stores Management"
                />
                {opens ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={opens} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(sessionStorage.getItem("PurchaseMaster") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/purchasemaster"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Purchase Master"
                        />
                      </ListItemButton>
                    </Link>
                  )}

                  {(sessionStorage.getItem("PurchaseEntry") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/purchaseentry"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Purchase Entry"
                        />
                      </ListItemButton>
                    </Link>
                  )}

                  {(sessionStorage.getItem("StoresEntry") === "1" ||
                    sessionStorage.getItem("superAdmin")) && (
                    <Link
                      to="/storesentry"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <FaDotCircle style={{ fontSize: 11 }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "13.6px",
                            color: "rgb(126, 110, 228)",
                          }}
                          primary="Stores Entry"
                        />
                      </ListItemButton>
                    </Link>
                  )}
                </List>
              </Collapse>
            </List>
          )}

          {(sessionStorage.getItem("MIS_Report") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick3}>
                <ListItemIcon>
                  <ReportGmailerrorredRoundedIcon></ReportGmailerrorredRoundedIcon>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="MIS Report"
                />
                {open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Link
                      to="/project-report"
                      style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}
                    >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText primary="Project" />
                  </ListItemButton>
                  </Link>
                </List>
                <List component="div" disablePadding>
                <Link
                      to="/lead-report"
                      style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}
                    >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Lead"
                    />
                  </ListItemButton>
                  </Link>
                </List>
                <List component="div" disablePadding>
                <Link
                      to="/sales-report"
                      style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}
                    >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Sales"
                    />
                  </ListItemButton>
                  </Link>
                </List>
                <List component="div" disablePadding>
                <Link
                      to="/agent-report"
                      style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}
                    >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Other"
                    />
                  </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </List>
          )}

{(sessionStorage.getItem("Alerts") === "1" || 
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick6}>
                <ListItemIcon>
                  <HiMiniBellAlert style={{fontSize:'20px'}}></HiMiniBellAlert>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Alerts"
                />
                {open6 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open6} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Link to='/cancel-customers' style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}>     <ListItemButton sx={{ pl: 3 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11, color: "rgb(126, 110, 228)" }} />
                    </ListItemIcon>
                <ListItemText primary="Cancel Customers" style={{ fontSize: 8, color: "rgb(126, 110, 228)" }}/>
                  </ListItemButton></Link> 
                </List>
                <List component="div" disablePadding>
                <Link to='/sale-agreement' style={{ color: "rgb(126, 110, 228)", textDecoration: "none" }}>     <ListItemButton sx={{ pl: 3 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11, color: "rgb(126, 110, 228)" }} />
                    </ListItemIcon>
                <ListItemText primary="Sale Agreement" style={{ fontSize:8, color: "rgb(126, 110, 228)" }}/>
                  </ListItemButton></Link> 
                </List>
                {/* <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Lead"
                    />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Sales"
                    />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Other"
                    />
                  </ListItemButton>
                </List> */}
              </Collapse>
            </List>
          )}

{(sessionStorage.getItem("Communication") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick7}>
                <ListItemIcon>
                <IoChatbubbleEllipses />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "13.6px",
                    color: "rgb(126, 110, 228)",
                  }}
                  primary="Communication"
                />
                {open7 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open7} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Link to='/admin-communication'>     <ListItemButton sx={{ pl: 3 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11, color: "rgb(126, 110, 228)" }} />
                    </ListItemIcon>
                <ListItemText primary="Messages" style={{ fontSize: 11, color: "rgb(126, 110, 228)" }}/>
                  </ListItemButton></Link> 
                </List>
                {/* <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Lead"
                    />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Sales"
                    />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FaDotCircle style={{ fontSize: 11 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "13.6px",
                        color: "rgb(126, 110, 228)",
                      }}
                      primary="Other"
                    />
                  </ListItemButton>
                </List> */}
              </Collapse>
            </List>
          )}




          {(sessionStorage.getItem("Analitics") === "1" ||
            sessionStorage.getItem("superAdmin")) && (
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <TrendingUpRoundedIcon></TrendingUpRoundedIcon>
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "13.6px",
                      color: "rgb(126, 110, 228)",
                    }}
                    primary="Analitics"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </div>
      </Drawer>

      <Main className="p-2" open={open}>
        <div>
       
          <div
            style={{
              height: "3.8rem",
              width: "100%",
              justifyContent: "space-between",
              border: "none",
              flexDirection: "row",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 90,
              borderRadius: "0.001rem",
            }}
            className="navigation-container card ps-3 pe-3"
          >

<div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-start",
                  marginLeft:'270px'
                  }}
                >
                 {userTypes!=="Accountant"? <h6 style={{ fontWeight: "bold",color:'rgb(126, 110, 228)'}}>
                   {ProjectName}
                  </h6>:null}
                </div>

                {/* Response hamburgIcon Commented if you need uncomment */}
              
            {/* <NotesRoundedIcon
              style={{ color: "rgb(126, 110, 228)", cursor: "pointer" }}
              onClick={() => {
                setOpen(!open);
              }}
            ></NotesRoundedIcon> */}



            <React.Fragment>
              {/* <h6 style={{marginTop:10}}>Hey {sessionStorage.getItem('user_name')}</h6> */}
            
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                 
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <h6 style={{ fontWeight: "bold" }}>
                    Welcome to the{" "}
                    {sessionStorage.getItem("superAdmin") === "superLogin"
                      ? "Admin"
                      : sessionStorage.getItem("user_name")}{" "}
                    Dashboard !
                  </h6>
                </div>

                {/* <Tooltip title="Account settings"> */}
                <Tooltip>
                  <IconButton
                    onClick={handleClick5}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open5 ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{
                        width: 37,
                        height: 37,
                        backgroundColor: "rgb(126, 110, 228)",
                      }}
                    >
                      <FaUser color="white" />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl1}
                id="account-menu"
                open={open5}
                onClose={handleClose5}
                onClick={handleClose5}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem onClick={handleClose5}>
                                    <Avatar /> Profile
                                </MenuItem>
                                <MenuItem onClick={handleClose5}>
                                    <Avatar /> My account
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClose5}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Add another account
                                </MenuItem>
                                <MenuItem onClick={handleClose5}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem> */}
                <MenuItem
                  color="red"
                  onClick={() => {
                    sessionStorage.removeItem("login");
                    window.location.reload();
                    sessionStorage.clear();
                    window.location.href = "/";
                  }}
                >
                  <ListItemIcon>
                    <Logout style={{ color: "red" }} fontSize="small" />
                  </ListItemIcon>
                  <Typography style={{ color: "red" }}>Logout</Typography>
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </div>

        <div style={{ marginTop: 70 }}>
          <Routes>
            {/* <Route path='/' element={<ProjectStatustAndGraf></ProjectStatustAndGraf>} ></Route> */}

            <Route path="/admin" element={<DashBoardHomePage />} />
            <Route
              path="/leadmanagement"
              element={<LeadManagementTable></LeadManagementTable>}
            ></Route>
            <Route path="/primary" element={<Primary />} />
            <Route path="/lead-transction" element={<LeadTransction />} />
            <Route path="/allocation" element={<Allocation />} />
            {/* <Route path='/project-basic' element={<Basic></Basic>} ></Route> */}
            <Route path="/project-basic" element={<Copybasic />}></Route>
            <Route path="/project-design" element={<Design></Design>}></Route>
            <Route path="/project-common" element={<Common></Common>}></Route>
            <Route path="/project-other" element={<Other></Other>}></Route>
            <Route path="/typical-units" element={<Typicalunits />}></Route>
            <Route
              path="/project-pricing"
              element={<Pricing></Pricing>}
            ></Route>
            <Route
              path="/project-instalments"
              element={<Payment></Payment>}
            ></Route>
            <Route
              path="/project-admin-account"
              element={<Admin></Admin>}
            ></Route>
            <Route path="/create-customer" element={<Customer />} />
            <Route path="/book-customer" element={<CustomerBooking />} />
            <Route path="/site-visit" element={<SiteVisit />} />
            <Route path="/block-form" element={<BlockForm />} />
            <Route path="/customer" element={<Customer-dash />} />
            <Route path="/customer-sales" element={<CustomerSales />} />
            <Route path="/project-blocks" element={<Blocks />} />
            <Route path="/project-towers" element={<Towers />} />
            <Route path="/project-floor" element={<Floors_Or_Unit />} />
            <Route path="/project-unit" element={<AddUnits />} />
            <Route path="/customer-billing" element={<CreateBilling />} />
            <Route path="/payment" element={<PaymentStatus />} />
            <Route path='/sales' element={<Sales />} />
            {/* <Route path="/sales" element={<Salescopy />} /> */}
            <Route path="/purchaseentry" element={<PurchaseEntry />} />
            <Route path="/storesentry" element={<StoreEntry />} />
            <Route path="/purchasemaster" element={<PurchaseMaster />} />
            <Route path="/project-cost" element={<Costadd />} />
            <Route path='/project-report' element={<ProjectReport/>}/>
            <Route path='/lead-report' element={<LeadReport/>}/>
            <Route path='/sales-report' element={<SalesReport/>}/>
            <Route path='/agent-report' element={<AgentReport/>}/>
            <Route path="/managerdashboard" element={<Manger />} />
            <Route path="/admin-dashboard" element={<AdminDashboard/>} />
            <Route path="/alerts" element={<Alerts />} />
            <Route path="/crmdashboard" element={<CrmHead />} />
            <Route path='/Pre-sales' element={<PreSales/>}/>
            <Route path='/payment-approve' element={<PaymentApprove/>}/>
            <Route path='/cancel-customers' element={<CancelCustomers/>}/>
            <Route path='/sale-agreement' element={<DocumentTable/>}/>
            <Route path='/admin-communication' element={<AdminCommunication/>}></Route>
            <Route
              path="/account-dashboard"
              element={<AccountantDashboard />}
            />
            <Route path="/" element={<Wellcome />} />
            {/* <Route exact path="/">
                        {sessionStorage.getItem('userType') === 'Sales Executive' && <Redirect to="/sales" />}
                        {sessionStorage.getItem('userType') === 'Manager' && <Redirect to="/purchaseentry" />}
                        </Route> */}
          </Routes>
        </div>
      </Main>
    </Box>
  );
}
